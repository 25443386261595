import React from 'reactn';
import { Helmet } from "react-helmet";

// components
import Footer from '../common/Footer';
import Header from '../common/Header';
import InteriorPage from '../common/InteriorPage';

// styles
import styles from './Privacy.module.scss';

export default function Privacy(props) {
	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - Privacy Policy</title>
					<meta name="description" property='og:description' content='View our privacy policy that governs our management of this website'/>
					<meta property='og:url' content='https://cathedralbrass.org/privacy'/>
					<meta property='og:type' content='article'/>
				</Helmet>
				<Header />
				<InteriorPage title="Privacy Policy">
					<div className={styles.privacyPage}>
						<p>The Cathedral Brass does not share personal information with third parties, 
							nor do we store information about your visit to this site, other than to 
							analyze and optimize your content and reading experience through the use 
							of cookies.</p>
						<p>You can turn off the use of cookies at any time by changing your browser 
							settings. The site's content and functionality will not be affected by this 
							change.</p>
						<p>We are not responsible for content from this site that is republished on 
							other web sites without our permission. This privacy policy is subject to 
							change without notice and was last updated on October 4th, 2013. If you 
							have any questions please <a href="mailto:info@cathedralbrass.org">contact us via email</a>.</p>
					</div>
				</InteriorPage>
			</div>
			<Footer />
		</>
	)
}
