import React, {useState} from 'reactn';
import ContactView from './Contact-view';

const Contact = props => {

	// component state
	const [postUrl] = useState(`https://cathedralbrass.us13.list-manage.com/subscribe/post?u=e7ed9791f767f65765a4a2bed&id=eab4c88fbc`);

	return (
		<ContactView
			autoFocus = {false}
			postUrl = {postUrl}
		/>
	)
}

export default Contact;
