import React from 'reactn';
import styles from './NavigationButton.module.scss';

export default function NavigationButtonView(props) {
	return (
		<button onClick={props.openNav} className={styles.treble} aria-label="Open Menu">
			Menu
		</button>
	)
}
