import React from 'reactn';
import ConductorSearchView from './ConductorSearch-view';

const ConductorSearch = props => {
	return (
		<ConductorSearchView />
	)
}

export default ConductorSearch;
