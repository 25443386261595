import React from 'reactn';
import { Helmet } from "react-helmet";

// components
import Footer from '../common/Footer';
import Header from '../common/Header';
import InteriorPage from '../common/InteriorPage';

// images
import FairfaxCommission from "./images/city-of-ffx-commisson-of-the-arts.png";
import GrahamBreedlove from "./images/grahamBreedlove.png";
import JohnCradler from "./images/johnCradler.jpg"
import LindseySmith from "./images/lindseySmith.png";
import Logo from "./images/DayOfBrass2024.png";
import TaraIslas from "./images/taraIslas.png";
import WilliamPorter from "./images/williamPorter.png";

// styles
import styles from './DayOfBrass2024.module.scss';

export default function DayOfBrass2024View(props) {
	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - Day of Brass II</title>
					<meta name="description" property='og:description' content='Learn about our second annual Day of Brass educational and performance event'/>
					<meta property='og:url' content='https://cathedralbrass.org/DayOfBrass'/>
					<meta property='og:type' content='article'/>
					<meta name="viewport" content="width=device-width, initial-scale=1" />
				</Helmet>
				<Header />
				<InteriorPage>
					<div className={styles.page}>
						<div className={styles.pageMain}>
							<div className={styles.pageTop}>
								<h2>The City of Fairfax Commission on the Arts<br/>and The Cathedral Brass present</h2>
								<h1>Day of Brass II</h1>
							</div>
							<div>
								<div className={styles.section}>
									<h2>About the Event</h2>
									<p>The Cathedral Brass is pleased to present our second annual DAY OF BRASS! This event is the representation of our efforts to create an engaging and enriching educational opportunity for young brass players in Washington Metro area. The purpose is to expose and perform traditional brass choir repertoire and work with highly accomplished and successful brass artists. We have the good fortune to having a very strong slate of clinicians to work with our participants, and it is sure to be an experience to remember for years to come!</p>
								</div>
								<div className={styles.section}>
									<h2>Guest Clinicians</h2>
									<div className={styles.bios}>
										<div className={styles.bio}>
											<div className={styles.photo}>
												<img src={JohnCradler} alt="" />
											</div>
											<div className={styles.name}>
												John Cradler
											</div>
											<div className={styles.cred}>
												Director, Cathedral Brass Youth Ensemble
											</div>
											<div className={styles.instrument}>
												Director
											</div>
										</div>
										<div className={styles.bio}>
											<div className={styles.photo}>
												<img src={TaraIslas} alt="" />
											</div>
											<div className={styles.name}>
												Tara Islas
											</div>
											<div className={styles.cred}>
												United States Air Force Band (Ret.)
											</div>
											<div className={styles.instrument}>
												Horn
											</div>
										</div>
										<div className={styles.bio}>
											<div className={styles.photo}>
												<img src={GrahamBreedlove} alt="" />
											</div>
											<div className={styles.name}>
												Graham Breedlove
											</div>
											<div className={styles.cred}>
												United States Army Blues, Adj. Prof. of Trumpet (GMU)
											</div>
											<div className={styles.instrument}>
												Trumpet
											</div>
										</div>
										<div className={styles.bio}>
											<div className={styles.photo}>
												<img src={LindseySmith} alt="" />
											</div>
											<div className={styles.name}>
												Lindsey Smith
											</div>
											<div className={styles.cred}>
												United States Air Force Band (Ret.)
											</div>
											<div className={styles.instrument}>
												Trombone
											</div>
										</div>
										<div className={styles.bio}>
											<div className={styles.photo}>
												<img src={WilliamPorter} alt="" />
											</div>
											<div className={styles.name}>
												Dr. William Porter
											</div>
											<div className={styles.cred}>
												United States Air Force Band (Ret.), Prof. of Tuba (GMU)
											</div>
											<div className={styles.instrument}>
												Tuba
											</div>
										</div>
									</div>
								</div>
								<div className={styles.section}>
									<h2>Tentative Schedule</h2>
									<table>
										<tbody>
											<tr>
												<td>9:00 a.m.&nbsp;&ndash;&nbsp;9:50 a.m.</td>
												<td>Registration</td>
											</tr>
											<tr>
												<td>10:00 a.m.&nbsp;&ndash;&nbsp;10:30 a.m.</td>
												<td>Reading Session</td>
											</tr>
											<tr>
												<td>10:30 a.m.&nbsp;&ndash;&nbsp;11:30 a.m.</td>
												<td>Sectionals</td>
											</tr>
											<tr>
												<td>11:30 a.m.&nbsp;&ndash;&nbsp;12:30 p.m.</td>
												<td>Master Class</td>
											</tr>
											<tr>
												<td>12:30 p.m.&nbsp;&ndash;&nbsp;1:00 p.m.</td>
												<td>Lunch*</td>
											</tr>
											<tr>
												<td>1:00 p.m.&nbsp;&ndash;&nbsp;3:00 p.m.</td>
												<td>Rehearsal</td>
											</tr>
											<tr>
												<td>3:00 p.m.&nbsp;&ndash;&nbsp;3:30 p.m.</td>
												<td>Rehearsal w/Cathedral Brass</td>
											</tr>
											<tr>
												<td>4:00 p.m.</td>
												<td>Concert</td>
											</tr>
										</tbody>
									</table>
									<p className={styles.footnote}>*Due to concerns with allergies, we are asking all students to bring their own bag lunch</p>
								</div>
								<div className={styles.section}>
									<h2>Registration</h2>
									<p>Registration is FREE for all participating students! In order to know the number of students participating and our instrumentation, we ask that you <a href="https://docs.google.com/forms/d/e/1FAIpQLSd454B1Hov-H0KaOXY9KUDeZuP1o0i7M2nfcPINoDKDqKY3cQ/viewform?usp=sf_link" target="_blank" rel="noreferrer">complete this registration form.</a></p>
								</div>
								<div className={styles.section}>
									<h2>Preparing for Day of Brass</h2>
									<p>Registrants should download, print, and familiarize themselves with the Day of Brass repertoire prior to the event. Music can be downloaded using <a href="https://drive.google.com/drive/folders/13tuzbV2kVwVxuDlLYvf3isPrC2U0ie28" target="_blank" rel="noreferrer">this link.</a></p>
								</div>
								<div className={styles.section}>
									<h2>Lunch</h2>
									<p>Due to the various dietary restrictions that may be present, we are asking that students pack their own lunch for this event. This ensures that every student has a meal and that every student is responsible for their own allergies. If your student does have allergies, please be sure to have any required medical assistance devices (epi-pens, etc.) available for use. We will provide bottled water for all participating students.</p>
								</div>
								<div className={styles.section}>
									<h2>Dress for the concert</h2>
									<p>The concert dress for the Cathedral Brass members is all black; however, students attending the workshop can dress comfortably for the day. We do ask that students be in business casual attire for the performance. If a student elects to be in business casual dress for the entire day, that is perfectly acceptable.</p>
								</div>
								<div className={styles.section}>
									<h2>Other things to consider</h2>
									<p>When a student registers, he/she will be given access to the selections that Mr. Cradler has recommended for performance. It is expected that the students will come to Day of Brass with the music in a state of preparation conducive to rehearsal. Students should bring all other items that are required for the day (mutes, valve oil, etc.).</p>
								</div>
							</div>
						</div>
						<div className={styles.pageSide}>
							<img src={Logo} alt="" />
							<div className={styles.ffxLogo}>
								<img src={FairfaxCommission} alt="City of Fairfax Commission on the Arts" />
							</div>
							<h3>Date & Time</h3>
							<p>March 2nd, 2024<br/>10:00 a.m.&nbsp;&ndash;&nbsp;3:30 p.m.<br/>
							Concert at 4:00 p.m.</p>
							<h3>Location</h3>
							<p><a href="https://www.google.com/maps/place/3740+Blenheim%20Blvd,%20Fairfax,%20VA%2022030,%20USA" target="_blank" rel="noreferrer">Stacy C. Sherwood Community Center<br/>
							Fairfax, Virginia</a></p>
							<h3>Cost</h3>
							<p>Free for all students grades 7&nbsp;&ndash;&nbsp;12</p>
							<h3>Registration</h3>
							<p><a href="https://docs.google.com/forms/d/e/1FAIpQLSd454B1Hov-H0KaOXY9KUDeZuP1o0i7M2nfcPINoDKDqKY3cQ/viewform?usp=sf_link" target="_blank" rel="noreferrer">Please complete this form</a></p>
						</div>
					</div>
				</InteriorPage>
			</div>
			<Footer />
		</>
	)
}
