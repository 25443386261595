import React from 'reactn';
import styles from './ZoomBox.module.scss';

export default function ZoomBox(props) {
	function makePhoto() {
		return (
			<div className={styles.zoomBoxImage}>
				<img src={props.zoomImage} alt="" />
			</div>
		)
	}

	if (props.zoomImage) {
		console.log('here');
		return (
			<div className="modalOverlay">
				<div className={styles.zoomBox}>
					{makePhoto()}
					<button
						className={styles.zoomBoxCloser}
						onClick={props.closeZoom}
					>
						Close Image
					</button>
				</div>
			</div>
		)
	}
	else {
		return null;
	}
}
