import React, {useState} from 'reactn';
import HeaderView from './Header-view';

const Header = props => {

	// component state
	const [navOpen, setNavOpen] = useState(false);

	function closeNav() {
		setNavOpen(false);
	}

	function openNav() {
		setNavOpen(true);
	}

	return (
		<HeaderView
			closeNav={closeNav}
			navOpen={navOpen}
			openNav={openNav}
		/>
	)

}

export default Header;
