import React, {useEffect, useState} from 'reactn';
import CalendarView from './Calendar-view';

const Calendar = props => {

	// component state
	const [events, setEvents] = useState(null);
	const [fetching, setFetching] = useState(false);
	const [timeMax, setTimeMax] = useState();
	const [timeMin, setTimeMin] = useState();
	const [yearList, setYearList] = useState();
	const [yearMax, setYearMax] = useState();
	const [yearMin, setYearMin] = useState();

	// other constants
	const CALENDAR_ID = "2av3ob9v3ubl53h1m8ee3e5amc%40group.calendar.google.com";
	const API_KEY = "AIzaSyCUtwOvrq5PfI7k5n1q2m1i3zBJbqnIDpI";

	useEffect(() => {
		if (events) {
			setFetching(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [events]);

	useEffect(() => {
		if (fetching) {
			listEvents();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetching]);

	useEffect(() => {
		if (!timeMax) {
			const currentYear = new Date().getFullYear();
			setTimeMax(new Date('31 December ' + currentYear + ' 23:59:59 EST'));
			setYearMax(currentYear);
		}
		else {
			if (timeMin && !fetching) setFetching(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeMax]);

	useEffect(() => {
		if (!timeMin) {
			const currentYear = new Date().getFullYear();
			setTimeMin(new Date('01 January ' + currentYear + ' 00:00:00 EST'));
			setYearMin(currentYear);
		}
		else {
			if (timeMax && !fetching) setFetching(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeMin]);

	useEffect(() => {
		if (!yearList) {
			const finalYear = new Date().getFullYear() + 2;
			let years = [];
			for (var i=2007; i<=finalYear; i++) {
				years.push(i);
			}
			setYearList(years);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [yearList]);

	function changeYearMax(newValue) {
		if (newValue >= yearMin) {
			setTimeMax(new Date('31 December ' + newValue + ' 23:59:59 EST'));
			setYearMax(newValue);
		}
	}

	function changeYearMin(newValue) {
		if (newValue <= yearMax) {
			setTimeMin(new Date('01 January ' + newValue + ' 00:00:00 EST'));
			setYearMin(newValue);
		}
	}

	async function listEvents() {

		// build the path with options
		let path = "https://www.googleapis.com/calendar/v3/calendars";
		path += "/" + CALENDAR_ID;
		path += "/events";
		path += "?key=" + API_KEY;
		path += "&orderBy=startTime";
		path += "&showDelete=false";
		path += "&singleEvents=true";
		path += "&timeMax=" + timeMax.toISOString();
		path += "&timeMin=" + timeMin.toISOString();

		// fetch from Google
		fetch(path)
		.then(response => {
			return response.json();
		})
		.then(events => {
			if (events && events.items) {
				setEvents(events.items);
			}
			else {
				console.log("No upcoming events were returned");
				setEvents(null);
			}
		})
	}

	return (
		<CalendarView
			changeYearMax = {changeYearMax}
			changeYearMin = {changeYearMin}
			events = {events}
			yearList = {yearList}
			yearMax = {yearMax}
			yearMin = {yearMin}
		/>
	)
}

export default Calendar;
