import React from 'reactn';
import styles from './InteriorPage.module.scss';

const InteriorPage = (props) => {
	return (
		<div className={styles.interiorPage}>
			{props.title &&
				<div className={styles.interiorPageTitle}>
					<div className={styles.interiorPageTitleInner}>
						{props.title}
					</div>
				</div>
			}
			<div className={styles.interiorPageContent}>
				<div className={styles.interiorPageContentInner}>
					{props.children}
				</div>
			</div>
		</div>
	)
}

export default InteriorPage;