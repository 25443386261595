import React, {useState} from 'reactn';
import { Helmet } from "react-helmet";

// components
import Footer from '../common/Footer';
import Header from '../common/Header';
import InputField from '../common/InputField';
import InteriorPage from '../common/InteriorPage';
import MailchimpSubscribe from "react-mailchimp-subscribe";

// images
import ContactPage from "./images/contactPage.png";

// styles
import styles from './Contact.module.scss';

export default function ContactView(props) {

	const CustomForm = ({ status, message, onValidated }) => {

		const [email, setEmail] = useState('');
		const [firstName, setFirstName] = useState('');
		const [lastName, setLastName] = useState('');

		const handleSubmit = (e) => {
			e.preventDefault();
			email &&
			firstName &&
			lastName &&
			email.indexOf("@") > -1 &&
			onValidated({
				EMAIL: email,
				MERGE1: firstName,
				MERGE2: lastName,
			});
		}

		return (
			<form className={styles.mailingListForm} onSubmit={(e) => handleSubmit(e)}>
				<>
					<div className={styles.mailchimpFieldContainer}>
						<InputField
							autoFocus = {props.autoFocus}
							label = "First Name"
							onChangeHandler = {setFirstName}
							placeholder = ""
							required = {true}
							type = "text"
							value = {firstName}
						/>
					</div>
		
					<div>
						<InputField
							label = "Last Name"
							onChangeHandler = {setLastName}
							placeholder = ""
							required = {true}
							type = "text"
							value = {lastName}
						/>
					</div>
		
					<div>
						<InputField
							label = "Email Address"
							onChangeHandler = {setEmail}
							placeholder = "your@email.com"
							required = {true}
							type = "email"
							value = {email}
						/>
					</div>

					{status === "sending" && (
						<div className={styles.mailchimpAlertSending}>
							sending...
						</div>
					)}
					{status === "error" && (
						<div 
							className={styles.mailchimpAlertError}
							dangerouslySetInnerHTML={{ __html: message }}
						/>
					)}
					{status === "success" && (
						<div
							className={styles.mailchimpAlertSuccess}
							dangerouslySetInnerHTML={{ __html: message }}
						/>
					)}

					<InputField
						label = "Subscribe"
						type = "submit"
						formValues = {[email, firstName, lastName]}
					/>
				</>
			</form>
		);
	};

	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - Contact</title>
					<meta name="description" property='og:description' content='Contact the Cathedral Brass for more information about the group and its activities'/>
					<meta property='og:url' content='https://cathedralbrass.org/contact'/>
					<meta property='og:type' content='article'/>
					<meta name="viewport" content="width=device-width, initial-scale=1" />
				</Helmet>
				<Header />
				<InteriorPage title="Contact">
					<div className={styles.contactPage}>
						<div className={styles.contactPageMain}>
							<p>The Cathedral Brass is available for concert performances and to support worship 
								services throughout the Washington DC Metropolitan Area. We perform in a variety 
								of ensemble configurations, including quintet, septet, dectet, and full brass choir.</p>
							<h2>How to reach us</h2>
							<p>
								Email: <a href="mailto:info@cathedralbrass.org">info@cathedralbrass.org</a><br />
								Facebook: <a href="https://www.facebook.com/groups/cathedralbrassva" target="_blank" rel="noreferrer">facebook.com/groups/cathedralbrassva</a><br />
								YouTube: <a href="https://youtube.com/channel/UC_uytSDtzYAurZGmFSD8rUg" target="_blank" rel="noreferrer">Cathedral Brass channel</a>
							</p>
							<h2>Join our mailing list</h2>
							<p>We&rsquo;ll send you notices about upcoming Cathedral Brass events. All the music, none of the spam.</p>
							<div className="mc__form-container">
								<MailchimpSubscribe
									url={props.postUrl}
									render={({ subscribe, status, message }) => (
										<CustomForm
											status = {status} 
											message = {message}
											onValidated = {formData => subscribe(formData)}
										/>
									)}
								/>
							</div>
						</div>
						<div className={styles.contactPageSide}>
							<img src={ContactPage} alt="" />
						</div>
					</div>
				</InteriorPage>
			</div>
			<Footer />
		</>
	)
}
