import React from 'reactn';
import ConductorSearchView from './ConductorSearch-view';
import { useNavigate } from 'react-router';

const ConductorSearch = props => {
	const navigate = useNavigate();

	function click() {
		navigate("/conductorsearch");
	}

	return (
		<ConductorSearchView
			click = {click}
		/>
	)
}

export default ConductorSearch;
