import React from 'reactn';
import { Helmet } from "react-helmet";

// components
import Footer from '../common/Footer';
import Header from '../common/Header';
import InteriorPage from '../common/InteriorPage';

// styles
import styles from './ConductorSearch.module.scss';

// images
import Baton from "./images/baton.jpg";

export default function DayOfBrass2024View(props) {
	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - Music Director and Conductor Search</title>
					<meta name="description" property='og:description' content='Learn about our second annual Day of Brass educational and performance event'/>
					<meta property='og:url' content='https://cathedralbrass.org/DayOfBrass'/>
					<meta property='og:type' content='article'/>
					<meta name="viewport" content="width=device-width, initial-scale=1" />
				</Helmet>
				<Header />
				<InteriorPage>
					<div className={styles.page}>
						<div className={styles.pageMain}>
							<div className={styles.pageTop}>
								<h2>The Cathedral Brass announces a</h2>
								<h1>Music Director and Conductor Search</h1>
							</div>
							<div>
								<div className={styles.section}>
									<p class={styles.leadIn}>The Cathedral Brass of Vienna, Virginia is excited to announce the launch of our search for a new Music Director and Conductor to join our vibrant and dedicated musical ensemble! This is an exciting opportunity for a passionate and experienced conductor to lead and inspire our ensemble.</p>
								</div>
								<div className={styles.section}>
									<h2>About Us</h2>
									<p>The Cathedral Brass is known for engaging and audience-friendly brass concerts that feature a diverse selection of repertoire, ranging from jazz and significant brass works through pop music from the 70s to today. We pride ourselves on our ability to excel in numerous genres, and this trait serves as the core of who we are.</p>
									<p>The Cathedral Brass features 20 to 23 of some of the finest retired military and community musicians in the DC metro region. Our organization consists of the full brass ensemble (The Cathedral Brass), and three smaller ensembles that include: the Jazz Combo, Trombone Quartet, and the Brass Quintet. The Cathedral Brass regularly performs in association with the City of Fairfax Commission on the Arts, The Gettysburg Brass Band Festival, and The National Community Band Festival, as well as numerous other events in the tri-state region.</p>
								</div>
								<div className={styles.section}>
									<h2>Position Overview</h2>
									<p>The Music Director and Conductor of the Cathedral Brass is responsible for:</p>
									<ul>
										<li>
											Organizing and leading rehearsals and performances with artistic vision and energy;
										</li>
										<li>
											Selecting compelling, diverse, and varied repertoire in conjunction with the Repertoire Selection Committee<sup>*</sup> (a three-person committee);
										</li>
										<li>
											Serving as an ex-officio member of the Board of Directors of the ensemble;
										</li>
										<li>
											Engaging with the community through outreach and educational initiatives.
										</li>
									</ul>
									<p className={styles.footnote}>*Much of our repertoire is arranged in-house, which drives the need for this committee.</p>
								</div>
								<div className={styles.section}>
									<h2>Remuneration</h2>
									<p>The Music Director and Conductor of The Cathedral Brass is a paid position. This is a per-service position with rehearsals being paid at the rate of $200 per rehearsal and concerts being paid at a rate of $300 per concert.</p>
								</div>
								<div className={styles.section}>
									<h2>Qualifications</h2>
									<p>The ideal candidate will possess:</p>
									<ul>
										<li>Proven experience in conducting ensembles, with a strong background in conducting numerous genres of music (classical, jazz, and pop);</li>
										<li>Exceptional leadership and communication skills;</li>
										<li>A stated desire to work with community musicians to build a positive and productive musical environment;</li>
										<li>A deep passion and understanding of brass music and an ability to foster a mutual passion for this music amongst the ensemble;</li>
										<li>The ability to work collaboratively and creatively with musicians of all levels and backgrounds;</li>
										<li><strong><em><u>While not required</u>, any candidate who enjoys and demonstrates skill in arranging music for brass and percussion would be given additional consideration.</em></strong></li>
									</ul>
								</div>
								<div className={styles.section}>
									<h2>Additional Details</h2>
									<ul>
										<li>Rehearsals are typically every two weeks on Saturday mornings at Vienna Presbyterian Church from 9:00 a.m. to 11:30 a.m.</li>
										<li>The Cathedral Brass typically stages four major performances per year. Typically, each performance is done in two separate venues, resulting in roughly eight performances per year.</li>
										<li>The Cathedral Brass also supports Vienna Presbyterian Church’s services on a monthly basis.</li>
									</ul>
								</div>
								<div className={styles.section}>
									<h2>Application Process</h2>
									<p>Interested candidates are invited to submit the following materials:</p>
									<ol>
										<li>A detailed resume highlighting relevant experience and accomplishments;</li>
										<li>A letter of application outlining your experience, your desire to lead The Cathedral Brass, and your philosophy regarding working with community musicians;</li>
										<li>References from any previous positions you have held, or with which you are active;</li>
										<li>Links to <em>ten minutes</em> of rehearsal footage, and <em>ten minutes</em> of performance footage.</li>
									</ol>
									<p>In the interest of time, we will not evaluate any more than what has been requested. Applications should be sent to: <a href="mailto:conductorsearch@cathedralbrass.org">conductorsearch@cathedralbrass.org</a>.</p>
								</div>
								<div className={styles.section}>
									<h2>Application Timeline</h2>
									<p>We will begin accepting applications on <em>July 15th</em> and we will stop receiving applications on <em>September 16th</em>. Through late September and early October, the search committee will view applications, set up phone interviews, and select at least two finalists for the position. Finalists will have the opportunity to rehearse the Cathedral Brass in November on a standardized set of materials provided by the search committee (likely a classical piece and a jazz/pop piece).</p>
								</div>
								<div className={styles.section}>
									<h2>Selection Process</h2>
									<p>The search search committee will review all applications and select finalists for an interview and an audition with the ensemble. Finalists will have the opportunity to conduct a rehearsal with the ensemble and present their vision for the ensemble to the search committee.</p>
									<p>We look forward to welcoming a new Music Director and Conductor who will bring fresh energy and creativity to our ensemble. For more information, please visit <a href="/">www.cathedralbrass.org</a> or contact Dereck Scott (current Music Director and Conductor) at <a href="mailto:dscott@flinthill.org">dscott@flinthill.org</a> or our Manager, Mark Herzing at <a href="mailto:mark.herzing@brycetech.com">mark.herzing@brycetech.com</a>.</p>									
								</div>
							</div>
						</div>
						<div className={`${styles.pageSide} noprint`}>
							<img src={Baton} alt="" />
						</div>
					</div>
				</InteriorPage>
			</div>
			<Footer />
		</>
	)
}
