import React from 'react';

const InputField = props => {

	switch(props.type) {
		case "submit":
			return (
				<input
					className = {props.className}
					type = 'submit'
					value = {props.label}
					disabled = {props.validateInput(props.formValues)}
				/>
			)
		case "textarea":
			return (
				<label className = {props.className}>
					{props.label}
					<textarea
						autoFocus = {props.autoFocus}
						name = {props.name}
						onChange = {(e) => props.onChangeHandler(e.target.value)}
						placeholder = {props.placeholder}
						required = {props.required}
						rows = {(props.rows ? props.rows : 7)}
						value = {props.value}
					/>
				</label>
			)
		default:
			return (
				<label className = {props.className}>
					{props.label}
					<input
						autoFocus = {props.autoFocus}
						name = {props.name}
						onChange = {(e) => props.onChangeHandler(e.target.value)}
						placeholder = {props.placeholder}
						required = {props.required}
						type = {props.type}
						value = {props.value}
					/>
				</label>
			)
	}
};

export default React.memo(InputField);