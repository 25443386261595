import React from 'reactn';
import styles from './BioBox.module.scss';

export default function BioBox(props) {
	if (props.bioData) {
		return (
			<div className="modalOverlay">
				<div className={styles.bioBox}>
					<div className={styles.bioBoxNameplate}>
						<p>{props.bioData.name}</p>
						<div className={styles.bioBoxTitle}>
							{props.bioData.title}
						</div>
						<button
							className={styles.bioBoxCloser}
							onClick={props.closeBio}
						>
							Close Bio
						</button>
					</div>
					{props.bioData.text}
				</div>
			</div>
		)
	}
	else {
		return null;
	}
}
