import React from 'reactn';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';

const Footer = () => {
	const thisYear = new Date().getFullYear();
	return (
		<div className={styles.footer}>
			<div className={styles.footerInner}>
				<div className={styles.column1}>
					<div className={styles.mailingListRow1}>
						Join our mailing list to be notified of upcoming events:
					</div>
					<div className={styles.mailingListRow2}>
						<Link to="/contact">Subscribe</Link>
					</div>
				</div>
				<div className={styles.column2}>
					<div className={styles.listContent}>
						<Link to="/contact">Contact Us</Link>
					</div>
					<div className={styles.listContent}>
						<a href="https://www.facebook.com/groups/cathedralbrassva" target="_blanks" rel="noreferrer">Facebook</a>
					</div>
					<div className={styles.listContent}>
						<a href="https://www.instagram.com/cathedralbrass/" target="_blanks" rel="noreferrer">Instagram</a>
					</div>
					<div className={styles.listContent}>
						<Link to="/privacy">Privacy Policy</Link>
					</div>
					<div className={styles.copyright}>
						All material &copy; Copyright {thisYear} The Cathedral Brass. All Rights Reserved.
					</div>
				</div>
			</div>
		</div>
	)
}
export default Footer;