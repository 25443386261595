import React from 'reactn';
import { Helmet } from "react-helmet";

// components
import Footer from '../common/Footer';
import Header from '../common/Header';
import InteriorPage from '../common/InteriorPage';

export default function ErrorPage(props) {
	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - Error</title>
					<meta name="description" property='og:description' content='The Cathedral Brass is a symphonic brass and percussion ensemble that performs works from all eras, ranging from baroque to jazz to rock and roll.'/>
					<meta property='og:url' content='https://cathedralbrass.org'/>
					<meta property='og:type' content='article'/>
				</Helmet>
				<Header />
				<InteriorPage title="Error">
					<p>
						An unexpected error has occurred: <strong>{props.error.statusText || props.error.message}</strong>
					</p>
				</InteriorPage>
			</div>
			<Footer />
		</>
	)
}
