import React, {useCallback, useEffect, useState} from 'reactn';
import HomeView from './Home-view';

const Home = props => {

	// component state
	const [bioData, setBioData] = useState();

	const escFunction = useCallback((event) => {
		if (event.key === "Escape") {
			closeBio();
		}
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);
		return () => {
		  document.removeEventListener("keydown", escFunction, false);
		};
	}, [escFunction]);

	function closeBio() {
		setBioData();
	}

	function openBio(name) {
		switch(name) {
			case 'dereckScott':
				setBioData({
					name: "Dereck Scott",
					title: "Music Director",
					text: (
						<>
							<p>Performing, educating, composing, and conducting, Dr. Dereck Scott brings high-energy, passionate music to the Northern Virginia region. Dr. Dereck Scott is currently Principal Cornet and Soloist with the Rockville Brass Band, and has been active in presenting energetic, artistic music recitals within the community.</p>
							<p>In addition to performing with the Rockville Brass Band, Dereck is a founding member of Ensemble 29 brass quintet, and performs with The Brass of the Potomac, The Capital Wind Ensemble, and various regional orchestras in the area. Prior to moving to the DC Metro area, Dereck was active in the Pittsburgh area, and performed regularly with the Wheeling Symphony Orchestra.</p>
							<p>As an arranger and composer, Dereck has over fifty arrangements to his name. Writing for any ensemble size or combination of instruments, Dereck’s music has been performed by both student, adult, and semi-professional ensembles.</p>
							<p>Professionally, Dereck is the Director of Bands at the Flint Hill School in Oakton, Virginia. He has also been a judge for the Maryland Music Educators’ Association, Virginia Band and Orchestra Director&rsquo;s Association, and has also been a clinician for Loudoun County Public Schools.</p>
						</>
					)
				});
				break;
			case 'lanceVining':
				setBioData({
					name: "L. Thomas Vining",
					title: "Conductor Emeritus",
					text: (
						<>
							<p>Lance Vining attended Westminster Choir College, Princeton, NJ, and Peabody Conservatory, Baltimore, MD. Currently, he is the Director of Worship and Music at Vienna Presbyterian Church, Vienna, VA.</p>
							<p>Before becoming Director of Worship, Lance served Vienna Presbyterian as Director of Music for sixteen years. He has performed as a tenor soloist with many orchestras, including the New York Philharmonic and the National Symphony. He is the former conductor of The Oratorio Society of Virginia and has guest conducted numerous orchestras nationwide.</p>
						</>
					)
				});
				break;
			case 'johnCradler':
				setBioData({
					name: "John Cradler",
					title: "Director, Youth Ensemble",
					text: (
						<>
							<p>John Cradler is the former Principal Tuba of the U.S. Marine Band, &ldquo;The President&rsquo;s Own&rdquo; in Washington, D.C. During his career with &ldquo;The President&rsquo;s Own&rdquo;, he performed for five U.S. Presidents, toured 48 of the United States, and performed in Japan, Singapore, and Switzerland. He has performed under the batons of John Williams, Osmo Vanska, Giancarlo Guerrero, Gerard Schwarz, Timothy Reynish, and many other world-renowned conductors.</p>
							<p>In addition to his duties with the Marine Band, Cradler has performed with The Washington Ballet, The Chesapeake Orchestra, The National Philharmonic Orchestra, The National Gallery Orchestra, The Brass Band of Battle Creek, and The Cleveland Opera. He is a founding member of the Tuba-Euphonium Quartet: <em>Off Bass Brass</em>, which has produced two award winning albums.</p>
							<p>Over the course of his career, Cradler has taught students ranging in age from 10 to 77 years old. His performing and teaching skills have earned him several invitations to International Tuba and Euphonium Conferences.</p>
							<p>Cradler earned his Bachelor of Music degree from The University of Wisconsin-Madison and his Master of Music Degree from The University of Akron.</p>
						</>
					)
				});
				break;
			case 'markHerzing':
				setBioData({
					name: "Mark Herzing",
					title: "Manager",
					text: (
						<>
							<p>Mark Herzing has been a member of the Cathedral Brass since 1989, and assumed the role of Manager in 2004. A graduate of Lawrence University and the John F. Kennedy School of Government, Herzing is an experienced tuba player who has performed in a wide variety of ensembles in the DC metro area, including the City of Fairfax Band, the Potomac Brass Quintet, and the Kaleidoscope Jazz Orchestra.</p>
						</>
					)
				});
				break;

			default:
				setBioData();
		}
	}

	return (
		<HomeView
			bioData = {bioData}
			closeBio = {closeBio}
			openBio = {openBio}
		/>
	)
}

export default Home;
