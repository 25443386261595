import React from 'reactn';
import { useRouteError } from "react-router-dom";
import ErrorPageView from './ErrorPage-view';

const ErrorPage = props => {

	// get the router error
	const error = useRouteError();

	// report it in the console
	console.error(error);

	return (
		<ErrorPageView
			error = {error}
		/>
	)
}

export default ErrorPage;
