import React from 'reactn';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

// components
import Footer from '../common/Footer';
import Header from '../common/Header';
import InteriorPage from '../common/InteriorPage';

// styles
import styles from './Calendar.module.scss';

export default function CalendarView(props) {

	let yearOptions = null;
	if (props.yearList && Array.isArray(props.yearList)) {
		yearOptions = props.yearList.map((year, i) => {
			return (
				<option value={year} key={"year-" + i}>{year}</option>
			)
		});
	}

	// format the list we were passed
	let EventsListFormatted = null;
	if (Array.isArray(props.events)) {
		let currentYear = null;
		if (props.events.length === 0) {
			EventsListFormatted = (
				<div className={styles.notFound}>
					<p>No events were found for the selected date range.</p>
				</div>
			)
		}
		else {
			EventsListFormatted = props.events.map((event, i) => {
				const dateTime = (event.start && event.start.dateTime) ? new Date(event.start.dateTime) : null;
				const month = (dateTime) ? dateTime.toLocaleString('en-US', { month: 'short' }) : null;
				const day = (dateTime) ? dateTime.getDate() : null;
				const year = (dateTime) ? dateTime.getFullYear() : null;

				// summary
				let summary = (event.summary) ? event.summary : null;

				/**
				 * Future feature: an event URL
				 * This will require a custom field or parsing the description
				 */

				// location
				let location = null;
				if (event.location) {

					/** Sad hack: When there is a "::" in the Google event location, split it.
					 * The first part is assumed to be the venue name (Empire State Building), the 
					 * second part is assumed to be a street address that can be turned into a 
					 * map link (20 W 34th St., New York, NY 10001 USA). The address must end in
					 * ", USA" to be converted to a link -- see makeMapLink()).
					 * 
					 * As if it all weren't ugly enough: we only do the map link thing if there is
					 * a "::", meaning every venue has to have a name. All of this could change, of
					 * course. For example, we could add a custom field to Google Calendar, store
					 * the venue name there, and remove all this hacking.
					 */
					if (event.location.indexOf('::') !== -1) {
						const locationSplit = event.location.split('::');
						location = locationSplit.map((line, n) => {
							return makeMapLink(line, i, n);
						});
					}
					else {
						location = (
							<div>{event.location}</div>
						)
					}
				}

				// time string
				let timeString = null;
				if (dateTime) {
					let hr = dateTime.getHours();
					let min = dateTime.getMinutes(); min = (min < 10) ? "0" + min : min;
					let ampm = (hr >= 12) ? "PM" : "AM";
					hr = (hr > 12) ? hr - 12: hr;
					timeString = hr + ":" + min + " " + ampm;
				}

				// description (strip HTML)
				const description = (event.description) ? event.description.replace(/(<([^>]+)>)/gi, "") : null;

				let yearDivider = null;
				if (year !== currentYear && props.yearMin !== props.yearMax) {
					yearDivider = (
						<h3>Events in {year}</h3>
					)
					currentYear = year;
				}
				return (
					<div key={"event" + i}>
						{yearDivider}
						<div className={styles.event}>
							<div className={styles.eventDateCard}>
								{month &&
									<div className={styles.eventDateCardMonth}>
										{month}
									</div>
								}
								{day &&
									<div className={styles.eventDateCardDay}>
										{day}
									</div>
								}
							</div>
							<div className={styles.eventDetails}>
								{summary &&
									<div className={styles.eventDetailsTitle}>
										{summary}
									</div>
								}
								{location &&
									<>
										{location}
									</>
								}
								{timeString &&
									<div className={styles.eventDetailsTime}>
										{timeString}
									</div>
								}
								{description &&
									<div className={styles.eventDetailsNotes}>
										{description}
									</div>
								}
							</div>
						</div>
					</div>
				)
			});
		}
	}

	function makeMapLink(s, index1, index2) {
		if (!s) return null;
		if (s.length > 5) {
			const endString = s.slice(-5);
			if (endString === ", USA") {
				let urlString = "https://www.google.com/maps/place/" + s.replace(' ','+');
				let urlTitle = s.slice(0, (s.length - 5)); // remove ", USA"
				return (
					<div key={"eloc"+index1+index2}>
						<a href={urlString} target="_blank" rel="noreferrer">
							{urlTitle}
						</a>
					</div>
				)
			}
			else {
				return (<div key={"eloc"+index1+index2}>{s}</div>)
			}
		}
		else {
			return (<div key={"eloc"+index1+index2}>{s}</div>)
		}
	}

	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - Calendar</title>
					<meta name="description" property='og:description' content='View a running calendar of events involving The Cathedral Brass and its members'/>
					<meta property='og:url' content='https://cathedralbrass.org/calendar'/>
					<meta property='og:type' content='article'/>
				</Helmet>
				<Header />
				<InteriorPage title="Calendar">
					<div className={styles.calendarDisplay}>
						<div className={styles.sidebar}>
							<p>We publish event information when details are finalized. If you&rsquo;d 
								like to be notified of Cathedral Brass events and changes, please subscribe
								to our mailing list using the form on our <Link to="/contact">Contact page</Link>.</p>
								<p>
									<a href="https://calendar.google.com/calendar/u/1?cid=MmF2M29iOXYzdWJsNTNoMW04ZWUzZTVhbWNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ" target="_blank" rel="noreferrer">Subscribe to this calendar »</a>
								</p>
						</div>
						<div className={styles.mainColumn}>
							<div className={styles.rangeControls}>
								<label htmlFor="minYearSelect">
									From:
								</label>
								<select
									disabled={false}
									id="minYearSelect"
									onChange={(e) => {props.changeYearMin(e.target.value);}}
									value={props.yearMin}
								>
									{yearOptions}
								</select>
								<label htmlFor="maxYearSelect">
									To:
								</label>
								<select
									disabled={false}
									id="maxYearSelect"
									onChange={(e) => {props.changeYearMax(e.target.value);}}
									value={props.yearMax}
								>
									{yearOptions}
								</select>
							</div>
							<div className={styles.eventsList}>
								{EventsListFormatted}
							</div>
						</div>
					</div>
				</InteriorPage>
			</div>
			<Footer />
		</>
	)
}
