import React from 'reactn';
import { Link, useLocation } from 'react-router-dom';
import NavigationButton from '../NavigationButton';
import NavigationPanel from '../NavigationPanel';
import styles from './Header.module.scss';

const Header = (props) => {

	const location = useLocation();

	function buildLogo(location) {
		if (location.pathname === '/') {
			return (
				<div className={styles.logo}></div>
			)
		}
		else {
			return (
				<Link to="/" aria-label="Cathedral Brass Home">
					<div className={styles.logo}></div>
				</Link>
			)
		}
	}

	return (
		<>
			<div className={styles.header}>
				<div className={styles.headerInner}>
					<NavigationPanel closeNav={props.closeNav} navOpen={props.navOpen} />
					<div className={styles.logoHolder}>
						<div className={styles.logo}>
							{buildLogo(location)}
						</div>
					</div>
					<div className={styles.menuHolder}>
						<NavigationButton openNav={props.openNav} />
					</div>
				</div>
			</div>
		</>
	)
}
export default Header;