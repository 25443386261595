import React from 'reactn';
import { Link, useLocation } from 'react-router-dom';
import styles from './NavigationPanel.module.scss';

export default function NavigationPanelView(props) {

	const location = useLocation();

	function createLink(linkTitle, internalLink, location) {
		if (internalLink) {
			return (location.pathname === internalLink) ? linkTitle : (<Link to={internalLink}>{linkTitle}</Link>);
		}
		else {
			return null;
		}

	}

	return (
		<div className={(props.navOpen) ? styles.navigationPanelOpen : styles.navigationPanel}>
			<button
				className={styles.navigationPanelClose}
				onClick={props.closeNav}
			>
				Close Menu
			</button>
			<ul>
				<li>{createLink("Home", "/", location)}</li>
				<li>{createLink("Calendar", "/calendar", location)}</li>
				<li>{createLink("Day of Brass II", "/DayOfBrass", location)}</li>
				<li>{createLink("Youth Ensemble", "/youth", location)}</li>
				<li>{createLink("Photo Gallery", "/photos", location)}</li>
				<li>{createLink("Contact", "/contact", location)}</li>
				<li>{createLink("Donate", "/donate", location)}</li>
				<li>{createLink("Privacy Policy", "/privacy", location)}</li>
				<li><a href="https://nextcloud.cathedralbrass.org" target="_blank" rel="noreferrer">Musicians&rsquo; Portal</a></li>
			</ul>
		</div>
	)
}
