import React from 'reactn';
import { Helmet } from "react-helmet";

// components
import Footer from '../common/Footer';
import Header from '../common/Header';
import InteriorPage from '../common/InteriorPage';

// images
import DonatePage from "./images/donatePage.png";

// styles
import styles from './Donate.module.scss';

export default function Donate() {
	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - Donate</title>
					<meta name="description" property='og:description' content='Donate to The Cathedral Brass to support our events and activities'/>
					<meta property='og:url' content='https://cathedralbrass.org/donate'/>
					<meta property='og:type' content='article'/>
				</Helmet>
				<Header />
				<InteriorPage title="Support the Cathedral Brass">
					<div className={styles.donatePage}>
						<div className={styles.pageMain}>
							<p>
								The Cathedral Brass is an independent 501c(3) non-profit organized in the State of Virginia. 
								Donations help us complete our mission of bringing music and music education to communities
								throughout Virginia and the DMV.
							</p>
							<p>
								Our operating budget is dedicated to stipends for guest artists and educators; to the rental, 
								transport, storage, and purchase of equipment; and to support performance requirements such
								as venue insurance. Your donation allows us to keep our performances free and, in most cases, 
								open to the public.
							</p>
							<p>
								Please consider supporting The Cathedral Brass with your tax-deductible contribution via
								the Paypal link below. If you are already a supporter, thank you!
							</p>
							<div className={styles.paypalForm}>
								<form id="paypal-form" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
									<input type="hidden" name="cmd" value="_s-xclick" />
									<input type="hidden" name="hosted_button_id" value="95YKW4ZZYAXNY" />
									<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" name="submit" alt="Donate to The Cathedral Brass" />
									<img alt="" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
								</form>
							</div>
						</div>
						<div className={styles.pageSide}>
							<img src={DonatePage} alt="" />
						</div>
					</div>
				</InteriorPage>
			</div>
			<Footer />
		</>
	)
}
