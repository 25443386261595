import React from 'reactn';
import { Helmet } from "react-helmet";

// components
import Footer from '../common/Footer';
import Header from '../common/Header';
import InteriorPage from '../common/InteriorPage';
import ZoomBox from './ZoomBox';

// images
import image001 from "./photos/20220612TheCathedralBrass001.jpg";
import image002 from "./photos/20220612TheCathedralBrass002.jpg";
import image003 from "./photos/20220612TheCathedralBrass003.jpg";
import image004 from "./photos/20220612TheCathedralBrass005.jpg";
import image005 from "./photos/20220612TheCathedralBrass006.jpg";
import image006 from "./photos/20220612TheCathedralBrass008.jpg";
import image007 from "./photos/20220612TheCathedralBrass011.jpg";
import image008 from "./photos/20220612TheCathedralBrass012.jpg";
import image009 from "./photos/20220612TheCathedralBrass013.jpg";
import image010 from "./photos/20220612TheCathedralBrass014.jpg";
import image011 from "./photos/20220612TheCathedralBrass015.jpg";
import image012 from "./photos/20220612TheCathedralBrass017.jpg";
import image013 from "./photos/20220612TheCathedralBrass019.jpg";
import image014 from "./photos/20220612TheCathedralBrass020.jpg";
import image015 from "./photos/20220612TheCathedralBrass021.jpg";
import image016 from "./photos/20220612TheCathedralBrass022.jpg";
import image017 from "./photos/20220612TheCathedralBrass023.jpg";
import image018 from "./photos/20220612TheCathedralBrass025.jpg";
import image019 from "./photos/20220612TheCathedralBrass026.jpg";
import image020 from "./photos/20220612TheCathedralBrass027.jpg";
import image021 from "./photos/20220612TheCathedralBrass030.jpg";
import image022 from "./photos/20220612TheCathedralBrass031.jpg";
import image023 from "./photos/20220612TheCathedralBrass032.jpg";
import image024 from "./photos/20220612TheCathedralBrass033.jpg";
import image025 from "./photos/20220612TheCathedralBrass037.jpg";
import image026 from "./photos/20220612TheCathedralBrass048.jpg";
import image027 from "./photos/20220612TheCathedralBrass052.jpg";
import image028 from "./photos/20220612TheCathedralBrass055.jpg";
import image029 from "./photos/20220612TheCathedralBrass060.jpg";
import image030 from "./photos/20220612TheCathedralBrass062.jpg";
import image031 from "./photos/20220612TheCathedralBrass063.jpg";
import image032 from "./photos/20220612TheCathedralBrass066.jpg";
import image033 from "./photos/2019sherzingIMG_4336.jpeg";
import image034 from "./photos/2019sherzingIMG_4334.jpeg";
import image035 from "./photos/2019sherzingIMG_4329.jpeg";
import image036 from "./photos/2019sherzingIMG_4325.jpeg";
import image037 from "./photos/2022squillenIMG_0109.JPG";
import image038 from "./photos/2022pherzingIMG_8347.jpg";
import image039 from "./photos/2020pherzingIMG_8729.jpg";
import image040 from "./photos/2018pherzingIMG_7409.jpg";
import image041 from "./photos/2015unknownIMG_0600.JPG";
import image042 from "./photos/2015unknownIMG_0598.JPG";
import image043 from "./photos/2015pherzingIMG_3698.jpg";
import image045 from "./photos/2011sherzingIMG_1106.JPG";
import image046 from "./photos/2009pherzingIMG_0085.JPG";
import image047 from "./photos/2009pherzingIMG_0083.JPG";
import image048 from "./photos/2021jtaylorDSC00001.jpg";
import image050 from "./photos/2021jtaylorDSC00003.jpg";
import image051 from "./photos/2021jtaylorDSC00004.jpg";
import image052 from "./photos/2021jtaylorDSC00005.jpg";
import image054 from "./photos/2021jtaylorDSC00007.jpg";
import image055 from "./photos/2021jtaylorDSC00008.jpg";
import image057 from "./photos/2021jtaylorDSC00010.jpg";
import image059 from "./photos/2021jtaylorDSC00012.jpg";
import image061 from "./photos/2021jtaylorDSC00014.jpg";
import image064 from "./photos/2021jtaylorDSC00017.jpg";
import image065 from "./photos/2021jtaylorDSC00018.jpg";
import image067 from "./photos/2021jtaylorDSC00020.jpg";
import image068 from "./photos/2021jtaylorDSC00002.JPG";
import image069 from "./photos/2021jtaylorDSC00006.JPG";
import image070 from "./photos/2021jtaylorDSC00007a.JPG";
import image071 from "./photos/2021jtaylorDSC00009.JPG";
import image072 from "./photos/2021jtaylorDSC00012a.JPG";
import image073 from "./photos/20231021pherzing.jpeg";
import image074 from "./photos/20231022-1sherzing.jpeg";
import image075 from "./photos/20231022-2sherzing.jpeg";
import image076 from "./photos/20231022-3sherzing.jpeg";

// styles
import styles from './Photos.module.scss';

export default function PhotosView(props) {

	function makePhoto(obj, attribution) {
		return (
			<div className={styles.photo}>
				<div className={styles.photoBox} style={{ backgroundImage: `url(${obj})`}} onClick={(e) => props.openZoom(obj)}>
					<div className={styles.photoBoxIndicator}></div>
				</div>
				<div className="photoAttribution">
					{attribution}
				</div>
			</div>
		)
	}

	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - Photo Gallery</title>
					<meta name="description" property='og:description' content='View photos of The Cathedral Brass through the years'/>
					<meta property='og:url' content='https://cathedralbrass.org/photos'/>
					<meta property='og:type' content='article'/>
				</Helmet>
				<Header />
				<InteriorPage title="Photo Gallery">
					<div className={styles.photosGrid}>
						{makePhoto(image076, "Suzanne Herzing")}{/* 2023 */}
						{makePhoto(image075, "Suzanne Herzing")}{/* 2023 */}
						{makePhoto(image074, "Suzanne Herzing")}{/* 2023 */}
						{makePhoto(image073, "Paul Herzing")}{/* 2023 */}
						{makePhoto(image001, "Art Pittman")}{/* 2022 */}
						{makePhoto(image002, "Art Pittman")}{/* 2022 */}
						{makePhoto(image003, "Art Pittman")}{/* 2022 */}
						{makePhoto(image004, "Art Pittman")}{/* 2022 */}
						{makePhoto(image005, "Art Pittman")}{/* 2022 */}
						{makePhoto(image006, "Art Pittman")}{/* 2022 */}
						{makePhoto(image007, "Art Pittman")}{/* 2022 */}
						{makePhoto(image008, "Art Pittman")}{/* 2022 */}
						{makePhoto(image009, "Art Pittman")}{/* 2022 */}
						{makePhoto(image010, "Art Pittman")}{/* 2022 */}
						{makePhoto(image011, "Art Pittman")}{/* 2022 */}
						{makePhoto(image012, "Art Pittman")}{/* 2022 */}
						{makePhoto(image013, "Art Pittman")}{/* 2022 */}
						{makePhoto(image014, "Art Pittman")}{/* 2022 */}
						{makePhoto(image015, "Art Pittman")}{/* 2022 */}
						{makePhoto(image016, "Art Pittman")}{/* 2022 */}
						{makePhoto(image017, "Art Pittman")}{/* 2022 */}
						{makePhoto(image018, "Art Pittman")}{/* 2022 */}
						{makePhoto(image019, "Art Pittman")}{/* 2022 */}
						{makePhoto(image020, "Art Pittman")}{/* 2022 */}
						{makePhoto(image021, "Art Pittman")}{/* 2022 */}
						{makePhoto(image022, "Art Pittman")}{/* 2022 */}
						{makePhoto(image023, "Art Pittman")}{/* 2022 */}
						{makePhoto(image024, "Art Pittman")}{/* 2022 */}
						{makePhoto(image025, "Art Pittman")}{/* 2022 */}
						{makePhoto(image026, "Art Pittman")}{/* 2022 */}
						{makePhoto(image027, "Art Pittman")}{/* 2022 */}
						{makePhoto(image028, "Art Pittman")}{/* 2022 */}
						{makePhoto(image029, "Art Pittman")}{/* 2022 */}
						{makePhoto(image030, "Art Pittman")}{/* 2022 */}
						{makePhoto(image031, "Art Pittman")}{/* 2022 */}
						{makePhoto(image032, "Art Pittman")}{/* 2022 */}
						{makePhoto(image037, "Sarah Quillen")}{/* 2022 */}
						{makePhoto(image038, "Paul Herzing")}{/* 2022 */}
						{makePhoto(image048, "John Taylor")}{/* 2021 */}
						{makePhoto(image050, "John Taylor")}{/* 2021 */}
						{makePhoto(image051, "John Taylor")}{/* 2021 */}
						{makePhoto(image052, "John Taylor")}{/* 2021 */}
						{makePhoto(image054, "John Taylor")}{/* 2021 */}
						{makePhoto(image055, "John Taylor")}{/* 2021 */}
						{makePhoto(image057, "John Taylor")}{/* 2021 */}
						{makePhoto(image059, "John Taylor")}{/* 2021 */}
						{makePhoto(image061, "John Taylor")}{/* 2021 */}
						{makePhoto(image064, "John Taylor")}{/* 2021 */}
						{makePhoto(image065, "John Taylor")}{/* 2021 */}
						{makePhoto(image067, "John Taylor")}{/* 2021 */}
						{makePhoto(image068, "John Taylor")}{/* 2021 */}
						{makePhoto(image069, "John Taylor")}{/* 2021 */}
						{makePhoto(image070, "John Taylor")}{/* 2021 */}
						{makePhoto(image071, "John Taylor")}{/* 2021 */}
						{makePhoto(image072, "John Taylor")}{/* 2021 */}
						{makePhoto(image039, "Paul Herzing")}{/* 2020 */}
						{makePhoto(image033, "Suzanne Herzing")}{/* 2019 */}
						{makePhoto(image034, "Suzanne Herzing")}{/* 2019 */}
						{makePhoto(image035, "Suzanne Herzing")}{/* 2019 */}
						{makePhoto(image036, "Suzanne Herzing")}{/* 2019 */}
						{makePhoto(image040, "Paul Herzing")}{/* 2018 */}
					 	{makePhoto(image041, "Suzanne Herzing")}{/* 2015 */}
					 	{makePhoto(image042, "Suzanne Herzing")}{/* 2015 */}
					 	{makePhoto(image043, "Paul Herzing")}{/* 2015 */}
					 	{makePhoto(image045, "Suzanne Herzing")}{/* 2011 */}
					 	{makePhoto(image046, "Paul Herzing")}{/* 2009 */}
					 	{makePhoto(image047, "Paul Herzing")}{/* 2009 */}
					</div>
					<ZoomBox closeZoom={props.closeZoom} zoomImage={props.zoomImage} />
				</InteriorPage>
			</div>
			<Footer />
		</>
	)
}
