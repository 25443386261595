import React from 'reactn';
import NavigationButtonView from './NavigationButton-view';

const NavigationPanel = props => {
	return (
		<NavigationButtonView
			openNav={props.openNav}
		/>
	)
}

export default NavigationPanel;
