import React from 'reactn';
import InputFieldView from './InputField-view';

const InputField= props => {

	const validateInput = values => {
		if (values.some(f => f === "") || values[0].indexOf("@") === -1) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<InputFieldView
			autoFocus = {props.autoFocus}
			className = {props.className}
			formValues = {props.formValues}
			label = {props.label}
			name = {props.name}
			onChangeHandler = {props.onChangeHandler}
			placeholder = {props.placeholder}
			required = {props.required}
			row = {props.rows}
			type={props.type}
			validateInput = {validateInput}
			value={props.value}
		/>
	)
}

export default InputField;
