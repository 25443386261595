import React from 'reactn';
import { Link } from 'react-router-dom';
import styles from './DonateButton.module.scss';

export default function DonateButton(props) {
	if (props.longForm) {
		return (
			<Link className={styles.donateButton} to={props.to}>
				<h3>Support the Cathedral Brass</h3>
				<p>We are an independent 501c(3) non-profit organized in the State of Virginia.</p>
			</Link>
		)
	}
	else {
		<Link className={styles.donateButton} to={props.to}>
			<h3>Support the Cathedral Brass</h3>
		</Link>
	}
}
