import React, {useCallback, useEffect, useState} from 'reactn';
// import { useLocation } from 'react-router-dom';
import PhotosView from './Photos-view';

const Photos = props => {

	// component state
	const [zoomImage, setZoomImage] = useState(null);

	// escape key handling 1
	const escFunction = useCallback((event) => {
		if (event.key === "Escape") {closeZoom();}
	}, []);

	// escape key handling 2
	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);
		return () => {document.removeEventListener("keydown", escFunction, false);};
	}, [escFunction]);

	function closeZoom() {
		setZoomImage(null);
	}
	function openZoom(obj) {
		setZoomImage(obj);
	}
	return (
		<PhotosView
			closeZoom = {closeZoom}
			openZoom = {openZoom}
			zoomImage = {zoomImage}
		/>
	)
}

export default Photos;
