import React from 'reactn';
import { Link } from 'react-router-dom';
import styles from './EventList.module.scss';

export default function EventListView(props) {

	function makeMapLink(s, index1, index2) {
		if (!s) return null;
		if (s.length > 5) {
			const endString = s.slice(-5);
			if (endString === ", USA") {
				let urlString = "https://www.google.com/maps/place/" + s.replace(' ','+');
				let urlTitle = s.slice(0, (s.length - 5)); // remove ", USA"
				return (
					<div key={"eloc"+index1+index2}>
						<a href={urlString} target="_blank" rel="noreferrer">
							{urlTitle}
						</a>
					</div>
				)
			}
			else {
				return (<div key={"eloc"+index1+index2}>{s}</div>)
			}
		}
		else {
			return (<div key={"eloc"+index1+index2}>{s}</div>)
		}
	}

	// format the list we were passed
	let EventsListFormatted = null;
	if (Array.isArray(props.events)) {
		EventsListFormatted = props.events.map((event, i) => {
			const dateTime = (event.start && event.start.dateTime) ? new Date(event.start.dateTime) : null;
			const month = (dateTime) ? dateTime.toLocaleString('en-US', { month: 'short' }) : null;
			const day = (dateTime) ? dateTime.getDate() : null;

			// summary
			let summary = (event.summary) ? event.summary : null;

			/**
			 * Future feature: an event URL
			 * This will require a custom field or parsing the description
			 */

			// location
			let location = null;
			if (event.location) {

				/** Check hack: when there is a "::" in the Google event location, split it.
				 * The first part is assumed to be the venue name (Statue of Liberty), the 
				 * second part is assumed to be a street address that can be turned into a 
				 * map link (assuming it ends in ", USA" -- see makeMapLink()).
				 * 
				 * As if it all weren't ugly enough: we only do the map link thing if there is
				 * a "::", meaning every venue has to have a name.
				 */
				if (event.location.indexOf('::') !== -1) {
					const locationSplit = event.location.split('::');
					location = locationSplit.map((line, n) => {
						return makeMapLink(line, i, n);
					});
				}
				else {
					location = (
						<div>{event.location}</div>
					)
				}
			}

			// time string
			let timeString = null;
			if (dateTime) {
				let hr = dateTime.getHours();
				let min = dateTime.getMinutes(); min = (min < 10) ? "0" + min : min;
				let ampm = (hr >= 12) ? "PM" : "AM";
				hr = (hr > 12) ? hr - 12: hr;
				timeString = hr + ":" + min + " " + ampm;
			}

			// description (strip HTML)
			const description = (event.description) ? event.description.replace(/(<([^>]+)>)/gi, "") : null;

			return (
				<div className={styles.event} key={"event" + i}>
					<div className={styles.eventDateCard}>
						{month &&
							<div className={styles.eventDateCardMonth}>
								{month}
							</div>
						}
						{day &&
							<div className={styles.eventDateCardDay}>
								{day}
							</div>
						}
					</div>
					<div className={styles.eventDetails}>
						{summary &&
							<div className={styles.eventDetailsTitle}>
								{summary}
							</div>
						}
						{location &&
							<>
								{location}
							</>
						}
						{timeString &&
							<div className={styles.eventDetailsTime}>
								{timeString}
							</div>
						}
						{description &&
							<div className={styles.eventDetailsNotes}>
								{description}
							</div>
						}
					</div>
				</div>
			)
		});
	}
	else {
		EventsListFormatted = (
			<div className={styles.event}>
				<div className={styles.eventDateCardYouTube}></div>
				<div className={styles.eventDetails}>
					<div className={styles.eventDetailsTitle}>
						The music never stops
					</div>
					<div>
						<a href="https://youtube.com/channel/UC_uytSDtzYAurZGmFSD8rUg" target="_blank" rel="noreferrer">
							Please visit our YouTube channel for some great recorded performances.
						</a>
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className={styles.EventListInner}>
				{EventsListFormatted &&
					<>
						<h3>Upcoming Events</h3>
						{EventsListFormatted}
						<div className={styles.calendarLink}>
							<Link to="/calendar">View our complete calendar »</Link>
						</div>
					</>
				}
			</div>
		</>
	)
}
