import React from 'reactn';
import DayOfBrass2024View from './DayOfBrass2024-view';

const DayOfBrass2024 = props => {
	return (
		<DayOfBrass2024View />
	)
}

export default DayOfBrass2024;
