import React, {useEffect, useState} from 'reactn';
import EventListView from './EventList-view';

const EventList = props => {

	// component state
	const [events, setEvents] = useState(null);

	// other constants
	const CALENDAR_ID = "2av3ob9v3ubl53h1m8ee3e5amc%40group.calendar.google.com";
	const API_KEY = "AIzaSyCUtwOvrq5PfI7k5n1q2m1i3zBJbqnIDpI";

	async function listUpcomingEvents() {

		// build the path with options
		let path = "https://www.googleapis.com/calendar/v3/calendars";
		path += "/" + CALENDAR_ID;
		path += "/events";
		path += "?key=" + API_KEY;
		path += "&maxResults=4";
		path += "&orderBy=startTime";
		path += "&showDelete=false";
		path += "&singleEvents=true";
		path += "&timeMin=" + new Date().toISOString();

		// fetch from Google
		fetch(path)
		.then(response => {
			return response.json();
		})
		.then(events => {
			if (events && events.items) {
				setEvents(events.items);
			}
			else {
				console.log("No upcoming events were returned");
				setEvents(null);
			}
		})
	}

	useEffect(() => {
		if (!events) {
			listUpcomingEvents();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [events]);

	return (
		<EventListView
			events = {events}
		/>
	)
}

export default EventList;
