import React from 'reactn';
import NavigationPanelView from './NavigationPanel-view';

const NavigationPanel = props => {
	return (
		<NavigationPanelView
			closeNav={props.closeNav}
			navOpen={props.navOpen}
		/>
	)
}

export default NavigationPanel;
